import "./style.scss";
import { IonContent, IonImg, IonPopover, IonTextarea } from "@ionic/react";
import { AppRate } from "@ionic-native/app-rate";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { xor } from "lodash";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { BottomButtons } from "./bottomButtons";
import { DetailReview } from "./detailReview";
import { InitialText } from "./initialText";
import { PopoverContentAnimation, enterAnimation, leaveAnimation } from "./popoverAnimation";
import { actionAddRating } from "../../store/ongoingShifts";
import { updateAgentAppRatingStatus } from "../api";
import { FeedbackPrompt, RatingReasons, StarDescriptionApp } from "../constants";
import { AppStarRating } from "../starRating";

interface AppReviewPrompt {
  onDidDismiss?: () => void;
}

const ReviewPrompt: FC<AppReviewPrompt> = ({ onDidDismiss }) => {
  const [rating, setRating] = useState<number>(0);
  const [dismissed, setDismiss] = useState<boolean>(false);
  const [reasons, setReasons] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState<string>("");
  const { appFeedback } = RatingReasons;
  const { prompt } = FeedbackPrompt;
  const feedbackText = rating > 4 ? prompt[1] : prompt[2];
  const { userId } = useDefinedAgent();
  const dispatch = useDispatch();
  const [playedLowRateAnimation, setPlayedLowRateAnimation] = useState<boolean>(false);
  const [playRevAnimation, setPlayRevAnimation] = useState<boolean>(false);
  const [shouldOtherAnimation, setShouldOtherAnimation] = useState<boolean>(false);

  const updateWorkerCache = useUpdateWorkerCache();

  const el = document.querySelector(".review-prompt .popover-content")!;
  const { fiveStarAnimation, lowRatingAnimation, otherRatingAnimation, reverseOtherAnimation } =
    PopoverContentAnimation(el);

  const handleStarClick = (starValue: number) => {
    if (rating === 0) {
      setRating(starValue);
    }
  };

  const handleReasonChange = (category: string) => {
    if (category === "Other Reason" && reasons.includes("Other Reason")) {
      setPlayRevAnimation(true);
    } else if (category === "Other Reason") {
      setShouldOtherAnimation(true);
    }
    setReasons((prevState) => xor(prevState, [category]));
  };

  const handleOtherReasonChange = (event: any) => {
    setOtherReason(event.target.value);
  };

  useEffect(() => {
    const updateAgent = async () => {
      try {
        await updateAgentAppRatingStatus(String(userId));
      } catch (error) {
        console.error(error);
      }
    };

    updateAgent();
  }, [userId]);

  const handleSubmit = () => {
    const reviewAppStoreConfig = {
      ios: "1472962791",
      android: "https://play.google.com/store/apps/details?id=health.clipboard.worker",
    };

    dispatch<any>(
      actionAddRating(
        {
          rating,
          reasons,
          otherReason,
          reviewFor: "APP",
          id: userId,
        },
        updateWorkerCache
      )
    );

    if (rating === 5) {
      AppRate.setPreferences({
        storeAppURL: {
          ios: reviewAppStoreConfig.ios,
          android: reviewAppStoreConfig.android,
        },
      });
      AppRate.navigateToAppStore();
    }
    setDismiss(true);
  };

  useEffect(() => {
    if (rating === 5) {
      fiveStarAnimation.play();
    } else if (playRevAnimation) {
      reverseOtherAnimation.onFinish(() => setPlayRevAnimation(false)).play();
    } else if (reasons.includes("Other Reason") && !playRevAnimation && shouldOtherAnimation) {
      otherRatingAnimation.onFinish(() => setShouldOtherAnimation(false)).play();
    } else if (!playedLowRateAnimation && rating > 0 && reasons.length === 0) {
      lowRatingAnimation.onFinish(() => setPlayedLowRateAnimation(true)).play();
    }
  }, [
    reasons,
    otherRatingAnimation,
    lowRatingAnimation,
    playedLowRateAnimation,
    fiveStarAnimation,
    reverseOtherAnimation,
    rating,
    playRevAnimation,
    shouldOtherAnimation,
  ]);

  return (
    <IonContent>
      <IonContent>
        <IonPopover
          isOpen={!dismissed}
          showBackdrop={true}
          backdropDismiss={false}
          enterAnimation={enterAnimation}
          leaveAnimation={leaveAnimation}
          cssClass="review-prompt"
          onDidDismiss={onDidDismiss}
        >
          {rating === 0 && (
            <IonImg
              onClick={() => setDismiss(true)}
              src="../../assets/icons/X.svg"
              className="popover-dismiss"
            />
          )}
          <InitialText animate={rating !== 0} />
          <AppStarRating
            facilityName=""
            rating={rating}
            onChange={handleStarClick as Dispatch<SetStateAction<number>>}
            starDescription={StarDescriptionApp}
            animate={rating !== 0}
          />
          {rating > 0 && (
            <>
              <DetailReview
                feedbackText={feedbackText}
                reasons={reasons}
                appFeedback={appFeedback}
                showReasons={rating < 5}
                onReasonChange={handleReasonChange}
              />
              <BottomButtons
                setDismiss={setDismiss}
                handleSubmit={handleSubmit}
                disabled={rating < 5 && reasons.length === 0}
              />
            </>
          )}
          {reasons.includes("Other Reason") && (
            <IonTextarea
              className="other-text"
              placeholder="Other Reason"
              value={otherReason}
              onIonChange={handleOtherReasonChange}
              autofocus
            />
          )}
        </IonPopover>
      </IonContent>
    </IonContent>
  );
};

export { ReviewPrompt };
