import { useSession } from "@src/app/store/helperHooks";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import moment from "moment-timezone";
import { useMemo } from "react";

export function useShowReviewPrompt(alreadyBookShift: boolean) {
  const { agent } = useSession();
  const ldFlags = useCbhFlags();

  const intervalFromLastTimeSeenReviewPromptPassed = useMemo(() => {
    if (!agent) {
      return false;
    }
    const { appRatingStatus } = agent;
    if (!appRatingStatus?.lastTimePromptWasShown) {
      return true;
    }
    const intervalInWeeks = Number(ldFlags[CbhFeatureFlag.HCP_APP_RATING_PROMPT_INTERVAL] ?? 1);
    if (intervalInWeeks === 0) {
      return true;
    }

    const dateToShowPromptAgain = moment(appRatingStatus?.lastTimePromptWasShown).add(
      intervalInWeeks,
      "weeks"
    );

    return moment().isAfter(dateToShowPromptAgain);
  }, [ldFlags, agent]);

  const meetsCriteriaToShowAppRatingPrompt = useMemo(() => {
    if (!agent) {
      return false;
    }
    const { appRatingStatus, attendance } = agent;
    if (!appRatingStatus || !attendance) {
      return false;
    }

    const shouldDisplayPrompt = ldFlags[CbhFeatureFlag.HCP_APP_RATING_PROMPT_INTERVAL] !== -1;
    const hasCompetedThreeShiftsSinceLastSeenPrompt =
      attendance?.completedShifts - appRatingStatus?.countCompletedShiftsWhenLastShownPrompt >= 3;

    return (
      appRatingStatus?.appRatingSubmitted === false &&
      appRatingStatus?.countPromptShown < 3 &&
      attendance?.completedShifts >= 3 &&
      hasCompetedThreeShiftsSinceLastSeenPrompt &&
      intervalFromLastTimeSeenReviewPromptPassed &&
      shouldDisplayPrompt &&
      alreadyBookShift
    );
  }, [agent, intervalFromLastTimeSeenReviewPromptPassed, ldFlags, alreadyBookShift]);

  return meetsCriteriaToShowAppRatingPrompt;
}
