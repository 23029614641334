import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { getAuthHeader } from "../superagent";

export const updateAgentAppRatingStatus = async (userId: string) => {
  try {
    const { body } = await request
      .put(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/appRatingStatus`)
      .set(await getAuthHeader())
      .send({
        agentId: userId,
      });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};
