import { CreateAnimation, IonButton, IonButtons, IonIcon, IonLabel } from "@ionic/react";
import { star, starOutline } from "ionicons/icons";
import { range } from "lodash";
import { Dispatch, FC, SetStateAction } from "react";

interface StarButtonProps {
  starValue: number;
  onChange: Dispatch<SetStateAction<number>>;
  isSelected: boolean;
}

const starValues = range(1, 6);
const StarButton: FC<StarButtonProps> = ({ starValue, onChange, isSelected }) => {
  const onStarClick = () => onChange(starValue);
  return (
    <IonButton fill="clear" color="primary" mode="ios" size="large" onClick={onStarClick}>
      <IonIcon icon={isSelected ? star : starOutline} mode="ios" size="large" />
    </IonButton>
  );
};

interface StarRatingProps {
  starDescription?: string[];
  rating: number;
  facilityName: string;
  onChange: Dispatch<SetStateAction<number>>;
  animate?: boolean;
}

export const AppStarRating: FC<StarRatingProps> = ({
  rating,
  onChange,
  starDescription,
  animate,
}) => {
  return (
    <CreateAnimation
      duration={500}
      iterations={1}
      keyframes={[
        { offset: 0, transform: "translateY(0%)" },
        { offset: 1, transform: "translateY(-104px)" },
      ]}
      play={animate}
    >
      <IonButtons className="review-star-buttons">
        {starValues.map((starValue) => (
          <StarButton
            data-testid={`star-option-${starValue}`}
            key={starValue}
            onChange={onChange}
            starValue={starValue}
            isSelected={rating >= starValue}
          />
        ))}
      </IonButtons>
      {starDescription ? (
        <IonLabel className="review-star-description">{starDescription[rating - 1]}</IonLabel>
      ) : null}
    </CreateAnimation>
  );
};
