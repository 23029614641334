import { IonButton } from "@ionic/react";

interface CategoryButtonProps {
  category: string;
  isSelected: boolean;
  onChange: (category: string) => void;
  isAppRating?: boolean;
}

export function CategoryButton(props: CategoryButtonProps) {
  const { category, isSelected, onChange, isAppRating = false } = props;
  const onClick = () => onChange(category);

  const fill = isSelected ? "solid" : "outline";
  const className = isAppRating ? "button-auto-height app-reasons-category" : "button-auto-height";

  return (
    <IonButton size="small" className={className} fill={fill} mode="ios" onClick={onClick}>
      {category}
    </IonButton>
  );
}
