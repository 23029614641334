export const StarDescriptionApp = [
  `It's Pretty Bad`,
  `It's Not Great`,
  `It's Fine`,
  `It's Good!`,
  `Amazing!`,
];

export const RatingReasons = {
  appFeedback: [
    "Not Enough Shifts",
    "Issues With the App",
    "Bad Experience at Work",
    "Issue With Being Paid",
    "Low Quality Facilities",
    `Don't Want To Use an App`,
    "Other Reason",
  ],
};

export const FacilityRatingReasons = {
  true: [
    "Respectful & Professional Workplace Environment",
    "Free from Hazardous Events Workplace Environment",
    "Sanitary Workplace Environment",
    "Easy to Understand Facility Policies",
    "Other Reason",
  ],
  false: [
    "Unethical & Unprofessional Workplace Environment",
    "Hazardous Workplace Environment",
    "Unsanitary Workplace Environment",
    "Facility Policies Confusing",
    "Other Reason",
  ],
};

export const FeedbackPrompt = {
  titleText: "What do you think of the Clipboard Health App?",
  solicitationText: "Your feedback is so important to everything we do here!",
  prompt: [
    `It'd mean a lot to us if you'd give us a review in the Play Store`,
    `It'd mean a lot to us if you'd give us a review in the App Store`,
    `It'd mean a lot to us if you'd tell us from being amazing!`,
  ],
};

export const ShiftRatingPrompt = {
  titleText: "How was your shift today at ",
  solicitationText: "Your feedback is so important to everything we do here!",
  prompt: {
    true: `We’d love to hear more about why your shift went well!`,
    false: `What kept your experience from being amazing?`,
  },
};
